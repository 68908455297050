import { Box, Button, Grid, Pagination, TextField, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import { useAppSelector } from "../../redux";
import { AppDispatch } from "../../redux/store";
import { getAllBreefs } from "../../service/BreefService";
import { getAllTeams } from "../../service/teamService";
import { useAddTenantForm } from "../../utils/forms";
import BreefTable, { Result } from "../manageBreef/BreefTable";
import { useTranslation } from "react-i18next";
import { updateAdminDashUser } from "../../service/UserService";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TeamTable, { TeamResult } from "../../manageTeam/TeamTable";

const UpdateViewEditTenant = () => {
  const [t] = useTranslation("global");
  const location = useLocation();
  const { userId, record } = location.state || "";
  const isEdit = new URLSearchParams(location.search).get("isEdit");
  const isMobile = useMediaQuery("(max-width:600px)");
  const formik = useAddTenantForm(!!isEdit, userId || "", isMobile);
  const [isEditing, setIsEditing] = useState(isEdit === "true");
  const [filteredData, setFilteredData] = useState<Result[]>([]);
  const [teamData, setTeamData] = useState<TeamResult[]>([]);

  const [teamCurrentPage, setTeamCurrentPage] = useState(1);
  const [breefCurrentPage, setBreefCurrentPage] = useState(1);
  const [pageLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [page, setPage] = useState(0); // Page number starts from 0
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const fetchBreefs = async (pageNo: number) => {
    const data = await getAllBreefs(dispatch, isMobile, navigate, userId, pageLimit, pageNo);
    if (data) {
      console.log("Data: ", data);

      setFilteredData(data.results);
    }
  };

  const fetchTeams = async (pageNo: number) => {
    const data = await getAllTeams(isMobile, navigate, userId,pageNo,rowsPerPage);
    if (data) {
      console.log("Team Data: ", data);

      setTeamData(data.teamList);
    }
  };

  useEffect(() => {
    console.log("Fetchign data");

    if (userId) {
      fetchBreefs(breefCurrentPage);
      fetchTeams(teamCurrentPage);
    }
  }, [userId, teamCurrentPage,breefCurrentPage]);

  const handleTeamPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    console.log("page val: ", value);

    setTeamCurrentPage(value);
  };
  
  const handleBreefPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    console.log("page val: ", value);

    setBreefCurrentPage(value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const data = {
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
      };
      await updateAdminDashUser(userId, data);
      setIsEditing(false);
    } catch (err) {
      console.error("Error editing user details: ", err);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    navigate("/manage-tenant");
  };

  const setUptenant = () => {
    formik.setValues({
      firstName: record?.firstName,
      lastName: record?.lastName,
      password: "",
      email: record?.email,
      phoneNumber: record?.phoneNumber,
      role: record?.role,
    });
  };
  useEffect(() => {
    setUptenant();
  }, [userId, isEdit]);

  return (
    <Layout>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          marginRight: "10%",
          marginTop: "2%",
          marginBottom: "1%",
        }}
      >
        <Button onClick={handleCancel} variant="outlined" sx={{ minWidth: "1%", marginLeft: "1%" }}>
          {"<"}
        </Button>
        <Typography
          variant="h4"
          fontWeight="bold"
          style={{ display: "flex", alignItems: "center", marginLeft: "6%" }}
        >
          {isEdit === "true" ? "Update" : "View"}
          {t("updateTenant.t1")} ({record?.firstName} {record?.lastName})
        </Typography>
      </div>
      <Box sx={{ display: "flex", flexDirection: "column", marginX: "10%", marginBottom: "5%" }}>
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoFocus
                required
                margin="dense"
                id="firstName"
                name="firstName"
                label={t("updateTenant.t2")}
                type="text"
                fullWidth
                variant="outlined"
                onChange={formik.handleChange("firstName")}
                value={formik.values.firstName}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                helperText={formik.touched.firstName && formik.errors.firstName}
                disabled={!isEditing}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                margin="dense"
                id="lastName"
                name="lastName"
                label={t("updateTenant.t3")}
                type="text"
                fullWidth
                variant="outlined"
                onChange={formik.handleChange("lastName")}
                value={formik.values.lastName}
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                helperText={formik.touched.lastName && formik.errors.lastName}
                disabled={!isEditing}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                margin="dense"
                id="email"
                name="email"
                label={t("updateTenant.t4")}
                type="email"
                fullWidth
                variant="outlined"
                onChange={formik.handleChange("email")}
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                id="phoneNumber"
                name="phoneNumber"
                label={t("updateTenant.t5")}
                type="text"
                fullWidth
                variant="outlined"
                onChange={formik.handleChange("phoneNo")}
                value={formik.values.phoneNumber}
                error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                disabled
              />
            </Grid>
          </Grid>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            {isEditing && (
              <>
                <Button
                  onClick={handleCancel}
                  variant="outlined"
                  sx={{
                    mr: 1,
                    color: "#ED670A",
                    borderColor: "#ED670A",
                    "&:hover": { borderColor: "#d55a07" },
                  }}
                >
                  {t("updateTenant.t6")}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ backgroundColor: "#ED670A", "&:hover": { backgroundColor: "#d55a07" } }}
                >
                  {t("updateTenant.t7")}
                </Button>
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Box>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            Teams
          </AccordionSummary>
          <AccordionDetails>
            <TeamTable
              results={teamData}
              page={teamCurrentPage}
              limit={pageLimit}
              totalPages={totalPages}
              totalResults={totalResults}
            />
            <Box sx={{ display: "flex", justifyContent: "center", my: 2, alignItems: "center" }}>
              <Pagination
                count={totalPages + 2}
                page={teamCurrentPage}
                onChange={handleTeamPageChange}
                sx={{
                  "& .MuiPaginationItem-root": {
                    borderColor: "#ED670A",
                  },
                  "& .Mui-selected": {
                    backgroundColor: "#ED670A",
                  },
                }}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3-content"
            id="panel3-header"
          >
            Breefs
          </AccordionSummary>
          <AccordionDetails>
            <BreefTable
              results={filteredData}
              page={breefCurrentPage}
              limit={pageLimit}
              totalPages={totalPages}
              totalResults={totalResults}
            />
            <Box sx={{ display: "flex", justifyContent: "center", my: 2, alignItems: "center" }}>
              <Pagination
                count={totalPages + 2}
                page={breefCurrentPage}
                onChange={handleBreefPageChange}
                sx={{
                  "& .MuiPaginationItem-root": {
                    borderColor: "#ED670A",
                  },
                  "& .Mui-selected": {
                    backgroundColor: "#ED670A",
                  },
                }}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Layout>
  );
};

export default UpdateViewEditTenant;
